<template>
  <div class="home">
    NOTFOUND PAGE
  </div>
</template>

<script>

export default {
  name: "NotFound",
};
</script>
